import { Image } from "../../../atoms/components";
import { CatalogCardLabel, CatalogCardLabelProps } from "../CatalogCardLabel";
import { CatalogCardLock } from "../CatalogCardLock";
import { CatalogCardExpireButton } from "../CatalogCardExpireButton";
import { daysFromTodayWithTime } from "../../../helpers/DateFormatting";
import { twMerge } from "tailwind-merge";

export interface CatalogCardProps extends CatalogCardLabelProps {
  name: string | null | undefined;
  locked?: boolean | null;
  lockIcon?: string;
  coverUri?: string | null | undefined;
  success?: boolean | null | undefined;
  isCourse?: boolean;
  type?: string | null | undefined;
  subtype?: string | null | undefined;
  key?: string;
  resourceIconStyle?: any;
  expiryDate?: string;
  daysTilExpired?: number;
  isInProgress?: boolean;
}

export function CatalogCard({
  name,
  locked = false,
  isInProgress = false,
  lockIcon,
  success,
  isCourse = false,
  coverUri,
  type,
  subtype,
  key,
  resourceIconStyle,
  expiryDate = "",
  daysTilExpired = 0,
}: CatalogCardProps) {
  return (
    <div
      key={key}
      className="relative z-0 flex flex-col items-center w-48 h-48 px-4 pt-4 mb-2 bg-gray-100 rounded-2xl md:mr-2"
    >
      <Image
        src={coverUri || ""}
        alt={name || ""}
        className="truncate h-28 w-28"
      ></Image>

      <p className="flex items-center justify-center w-full mx-auto my-auto font-semibold text-center text-gray-700 break-words">
        <div className="line-clamp-2">{name}</div>
      </p>

      <div className="absolute top-0 left-0 z-10 m-2">
        <CatalogCardLabel
          type={type}
          subtype={subtype}
          isCourse={isCourse}
          success={success}
          resourceIconStyle={resourceIconStyle}
          isInProgress={isInProgress}
        />
      </div>

      {daysTilExpired !== 0 &&
      daysFromTodayWithTime(expiryDate || "") !== "" &&
      daysFromTodayWithTime(expiryDate || "") !== "expired" ? (
        <div className="absolute top-0 right-0 z-10 m-2 rounded-lg shadow-sm bg-error-200">
          <CatalogCardExpireButton
            expiryDate={expiryDate || ""}
            daysTilExpired={daysTilExpired || 0}
          ></CatalogCardExpireButton>
        </div>
      ) : null}

      {locked ? (
        <div className="absolute top-0 left-0 z-20">
          {daysFromTodayWithTime(expiryDate || "") === "expired" ? (
            <CatalogCardLock lockIcon={"timer_off"} />
          ) : (
            <CatalogCardLock lockIcon={lockIcon} />
          )}
        </div>
      ) : null}

      {/* {locked && !isInProgress && type === "assessment" ? (
        <div className="absolute top-0 left-0 z-20">
          {daysFromTodayWithTime(expiryDate || "") === "expired" ? (
            <CatalogCardLock lockIcon={"timer_off"} />
          ) : (
            <CatalogCardLock lockIcon={lockIcon} />
          )}
        </div>
      ) : null} */}
    </div>
  );
}
