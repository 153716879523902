import {
  useState,
  useEffect,
  useLayoutEffect,
  useContext,
  useRef,
} from "react";
import {
  Image,
  Button,
  ExternalLink,
  Markdown,
  Loading,
  Video,
} from "../../../atoms/components";
import { IconButton, LoadingCentered } from "../../../molecules/components";
import "./GbfSlide.css";
import defaultPhoto from "../../../assets/defaultPhoto.png";
import {
  ResourceContentBurislideSpread as ResourceContentBurislideSpreadOriginal,
  useGbfSlideViewQuery,
  useAssessmentSubmitV2Mutation,
  ExamSubmission as ExamSubmissionOriginal,
  CourseBlockUuidUserStatusSubmission as CourseBlockUuidUserStatusSubmissionOriginal,
  ExamResponse as ExamResponseOriginal,
  useRecipeCookMutation,
  ResourceContentBurislideSpreadStyleInfo as ResourceContentBurislideSpreadStyleInfoOriginal,
  ResourceContentBurislideSpreadActivityGroupFlag as ResourceContentBurislideSpreadActivityGroupFlagOriginal,
  ResourceContentBurislideSpreadActivityGroupExamChoiceTriggerFlag as ResourceContentBurislideSpreadActivityGroupExamChoiceTriggerFlagOriginal,
  ResourceContentBurislideSpreadActivityGroupExamItemRequiredFlag as ResourceContentBurislideSpreadActivityGroupExamItemRequiredFlagOriginal,
  useGetSubmissionUuidMutation,
  ResourceDetailsInput,
} from "../../../generated/graphql";
import { CamelCasedType } from "../../../helpers/components";
import { ExamItemV2 } from "../AssessmentViewerV2";
import { ExamItemType } from "../../../molecules/components/AssessmentItemFeedback";
import { useSearchParams } from "react-router-dom";
import { ViewResourceLink } from "../../../organisms/components/ViewResourceLink";
import {
  ViewResourceMarkdown,
  ViewResourcePdf,
  ViewResourceVideo,
  MultipleChoiceSlideActivity,
  PollSlideActivity,
  LongAnswerSlideActivity,
  ShortAnswerSlideActivity,
  CheckBoxSlideActivity,
  FreedomWallSlideActivity,
  TallySlideActivity,
  ScrollCarouselSlide,
  CarouselSlide,
  ActivityGroupSlide,
} from "../../../organisms/components";
import { VideoObject } from "../../../atoms/components/Video";
import {
  ExamSubmissionInputV2,
  ExamResponseInput,
} from "../../../generated/graphql";
import { parseColorToStyle, ColorObj } from "../../../helpers/ColorHelper";
import { twMerge } from "tailwind-merge";
import { LastPageContext } from "../ViewResourceV2";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { useOnlineStatus } from "../../../helpers/OnlineStatus/OnlineStatus";
import { useCreateUrlFromBlob } from "../../../helpers/CreateUrlFromBlob/CreateUrlFromBlob";

export type ResourceContentBurislideSpread =
  CamelCasedType<ResourceContentBurislideSpreadOriginal>;

export type ExamResponse = CamelCasedType<ExamResponseOriginal>;

export type ExamSubmission = CamelCasedType<ExamSubmissionOriginal>;
export type ResourceContentBurislideSpreadStyleInfo =
  CamelCasedType<ResourceContentBurislideSpreadStyleInfoOriginal>;

export type CourseBlockUuidUserStatusSubmission =
  CamelCasedType<CourseBlockUuidUserStatusSubmissionOriginal>;

export type ResourceContentBurislideSpreadActivityGroupFlag =
  CamelCasedType<ResourceContentBurislideSpreadActivityGroupFlagOriginal>;

export type ResourceContentBurislideSpreadActivityGroupExamChoiceTriggerFlag =
  CamelCasedType<ResourceContentBurislideSpreadActivityGroupExamChoiceTriggerFlagOriginal>;

export type ResourceContentBurislideSpreadActivityGroupExamItemRequiredFlag =
  CamelCasedType<ResourceContentBurislideSpreadActivityGroupExamItemRequiredFlagOriginal>;

export type FlagObject = {
  flagId: string;
  spreadIndex: number;
};

export type SpreadInfo = {
  spreadIndex: number;
  slideIndex: number;
  slideLength: number;
  itemUuid?: string;
  hasResultsPage?: boolean;
  showResults: boolean;
  isBranchingSlide: boolean;
};
export interface GbfSlideProps {
  resourceDetailsInput: ResourceDetailsInput;
  referenceId: string;
  onLastPage: () => void;
}

export function GbfSlide({
  resourceDetailsInput,
  referenceId,
  onLastPage,
}: GbfSlideProps) {
  const { data, error, loading } = useGbfSlideViewQuery({
    variables: {
      resourceDetailsInput: resourceDetailsInput,
    },
  });

  const [currentSpreadIndex, setCurrentSpreadIndex] = useState<number>(0);
  const [spreadScreenDuration, setSpreadScreenDuration] = useState(0);
  const [isNavDisabled, setIsNavDisabled] = useState(false);
  const [isBackArrowDisabled, setIsBackArrowDisabled] = useState(false);
  const [response, setResponse] = useState<ExamResponseInput>();
  const [searchParams] = useSearchParams();
  const courseBlockUuid = searchParams.get("course_block_uuid");
  const { isOnline, loading: onlineStatusLoading } = useOnlineStatus();

  const spreads =
    data?.resourceViewScreenV2?.resource?.content?.__typename ===
      "ResourceContentBurislide" ||
    data?.resourceViewScreenV2?.resource?.content?.__typename ===
      "ResourceContentBuribookWithActivity"
      ? data.resourceViewScreenV2.resource.content.spreads
      : null;

  const spreadLength = spreads?.length || 1;

  const manageNavDisabled = (isNavDisabled: boolean) => {
    setIsNavDisabled((prev) => isNavDisabled);
  };

  const handleBackArrowClick = () => {
    if (!isBackArrowDisabled) {
      // Disable the button
      setIsBackArrowDisabled(true);

      // Perform your action here
      // For example, you can make an API call or trigger some other asynchronous operation

      // After the operation is complete, re-enable the button
      setTimeout(() => {
        setIsBackArrowDisabled(false);
      }, 300); // You can adjust the timeout based on your use case
    }
  };

  const [examTimeslotUuid, setExamTimeslotUuid] = useState<string | null>(null);

  const [getSubmissionUuid, getSubmissionUuidResponse] =
    useGetSubmissionUuidMutation();

  const submissionUuid =
    getSubmissionUuidResponse.data?.assessmentSubmitV2
      ?.courseBlockSubmissionResult?.uuid ||
    getSubmissionUuidResponse.data?.assessmentSubmitV2?.submissionResult?.uuid;

  const [
    assessmentSubmitV2,
    {
      data: assessmentResponseData,
      loading: assessmentResponseLoading,
      error: assessmentResponseError,
    },
  ] = useAssessmentSubmitV2Mutation();

  const [savedResponses, setSavedResponses] = useState<ExamResponseInput[]>();

  const [savedFeedbacks, setSavedFeedbacks] = useState<ExamResponse[]>([]);

  const submitResponses = (autoGoNextLogic?: () => void) => {
    if (
      examTimeslotUuid &&
      response &&
      ((response.choices && response.choices.length > 0) ||
        response.short_input ||
        response.long_input)
    ) {
      const _submission = {
        status: "in_progress",
        timeslot: examTimeslotUuid ? examTimeslotUuid : "",
        responses: response,
      };

      assessmentSubmitV2({
        variables: {
          courseScheduleUuid: resourceDetailsInput.scheduleUuid,
          submissionUuid: submissionUuid || "",
          updatedSubmission: _submission as ExamSubmissionInputV2,
          blockUuid: courseBlockUuid || null,
        },
      }).then((result) => {
        const resultData =
          (result.data?.assessmentSubmitUpdateV2?.courseBlockSubmissionResult?.responses?.find(
            (responseObject) => responseObject?.item === response.item
          ) ||
            result.data?.assessmentSubmitUpdateV2?.submissionResult?.responses?.find(
              (responseObject) => responseObject?.item === response.item
            )) as ExamResponse;

        setSavedFeedbacks((prev) => {
          if (resultData === undefined) return [...prev];

          if (prev.length > 0) {
            const newData = [...prev, resultData];
            return newData;
          }
          return [resultData];
        });

        setSavedResponses((prev) => {
          if (prev) {
            return [...prev, response];
          }
          return [response];
        });

        if (spreads?.[currentSpreadIndex]?.spreadType === "activity-group") {
          if (autoGoNextLogic) {
            autoGoNextLogic();
          }
        }

        if (
          !spreads?.[currentSpreadIndex]?.examItem?.showResults &&
          spreads?.[currentSpreadIndex]?.spreadType !== "activity-group"
        ) {
          if (currentSpreadIndex !== (spreads?.length || 1) - 1) {
            setCurrentSpreadIndex((prev) => prev + 1);
          }
        }
      });
    }
  };

  const [activeFlags, setActiveFlags] = useState<FlagObject[]>([]);

  const [currentTriggerFlags, setCurrentTriggerFlags] =
    useState<
      ResourceContentBurislideSpreadActivityGroupExamChoiceTriggerFlag[]
    >();
  const [currentRequiredFlags, setCurrentRequiredFlags] =
    useState<
      ResourceContentBurislideSpreadActivityGroupExamItemRequiredFlag[]
    >();

  const manageItemFlags = (
    examChoiceTriggerFlags: ResourceContentBurislideSpreadActivityGroupExamChoiceTriggerFlag[],
    examItemRequiredFlags: ResourceContentBurislideSpreadActivityGroupExamItemRequiredFlag[]
  ) => {
    if (examItemRequiredFlags) setCurrentRequiredFlags(examItemRequiredFlags);

    if (examChoiceTriggerFlags) setCurrentTriggerFlags(examChoiceTriggerFlags);
  };

  const [currentActivityGroupSlide, setCurrentActivityGroupSlide] =
    useState<ExamItemV2>({});

  const manageCurrentActivityGroupSlide = (examItem: ExamItemV2) => {
    setCurrentActivityGroupSlide(examItem);
  };

  const [cookDuration] = useRecipeCookMutation({
    variables: {
      ingredient: {
        recipe_name: "slides_activity",
        body: {
          object: {
            resource_id: resourceDetailsInput.resourceUuid,
            slide_index: currentSpreadIndex,
          },
          result: {
            seconds: spreadScreenDuration,
          },
          verb: "attempted",
          timestamp: new Date().toISOString(),
        },
      },
    },
  });

  const navigateSlide = (
    action: "previous" | "next" | "submit" | "finish",
    autoGoNextLogic?: () => void
  ) => {
    if (action === "submit") {
      if (autoGoNextLogic) {
        submitResponses(autoGoNextLogic);
      } else {
        submitResponses();
      }
    }

    if (action === "finish") {
      onLastPage();
      cookDuration();
      setSpreadScreenDuration(0);
    }

    if (action === "previous" || action === "next") {
      cookDuration();
      setSpreadScreenDuration(0);

      if (action === "previous") {
        setCurrentSpreadIndex((prev) => prev - 1);
        manageNavDisabled(false);
      }

      if (action === "next") {
        setCurrentSpreadIndex((prev) => prev + 1);
      }
    }
  };

  const [spreadInfos, setSpreadInfo] = useState<SpreadInfo[]>([]);

  const manageSpreadInfo = (spreadInfo?: SpreadInfo) => {
    if (spreadInfo) {
      const newSpreadInfoValue = spreadInfos.filter(
        (spreadInfoElement) =>
          spreadInfoElement.spreadIndex !== spreadInfo?.spreadIndex
      );

      const latestSpreadInfo = [...newSpreadInfoValue, spreadInfo];
      setSpreadInfo(latestSpreadInfo);
      return latestSpreadInfo;
    }

    // In case you want to access the current status of spread infos
    return spreadInfos;
  };

  const spreadComponentArray = spreads?.map((item, index) => {
    const handleResponse = (response: ExamResponseInput) => {
      setResponse(response);
    };

    const responseLength =
      assessmentResponseData?.assessmentSubmitUpdateV2?.submissionResult
        ?.responses?.length ||
      assessmentResponseData?.assessmentSubmitUpdateV2
        ?.courseBlockSubmissionResult?.responses?.length ||
      0;
    const responseItemUuid =
      assessmentResponseData?.assessmentSubmitUpdateV2?.submissionResult
        ?.responses?.[responseLength - 1]?.item ||
      assessmentResponseData?.assessmentSubmitUpdateV2
        ?.courseBlockSubmissionResult?.responses?.[responseLength - 1]?.item ||
      "Yeah nothiing ";

    const alreadyAnswered =
      assessmentResponseData?.assessmentSubmitUpdateV2?.submissionResult?.responses?.some(
        (response) =>
          response?.item ===
          (item?.examItem?.uuid ||
            manageSpreadInfo().find((info) => info.spreadIndex === index)
              ?.itemUuid)
      ) ||
      assessmentResponseData?.assessmentSubmitUpdateV2?.courseBlockSubmissionResult?.responses?.some(
        (response) =>
          response?.item ===
          (item?.examItem?.uuid ||
            manageSpreadInfo().find((info) => info.spreadIndex === index)
              ?.itemUuid)
      );

    const globalAppearance =
      data?.resourceViewScreenV2?.resource?.content?.__typename ===
        "ResourceContentBurislide" ||
      data?.resourceViewScreenV2?.resource?.content?.__typename ===
        "ResourceContentBuribookWithActivity"
        ? data.resourceViewScreenV2.resource.content.appearance
        : {};

    const globalGeneralAppearance = parseColorToStyle({
      backgroundColor: globalAppearance?.base?.backgroundColor,
      fontFamily: globalAppearance?.base?.fontFamily,
      foregroundColor: globalAppearance?.base?.foregroundColor,
    });
    const globalBgColor = parseColorToStyle({
      backgroundColor: globalAppearance?.base?.backgroundColor,
    });
    const globalTextStyle = parseColorToStyle({
      fontFamily: globalAppearance?.base?.fontFamily,
      foregroundColor: globalAppearance?.base?.foregroundColor,
    });

    return (
      <div
        key={item?.examItemUuid}
        className="relative flex flex-col justify-center w-full h-full my-auto"
      >
        {assessmentResponseLoading ? (
          <div className="w-full">
            <LoadingCentered />
          </div>
        ) : (
          ""
        )}
        <div className="flex-1 mx-2 max-h-[88vh] box-border py-4">
          <SpreadTypeSwitch
            key={index}
            item={item as ResourceContentBurislideSpread}
            savedResponses={savedResponses || []}
            handleResponse={handleResponse}
            savedFeedbacks={savedFeedbacks}
            manageNavDisabled={manageNavDisabled}
            manageItemFlags={manageItemFlags}
            activeFlags={activeFlags}
            manageCurrentActivityGroupSlide={manageCurrentActivityGroupSlide}
            id={index}
            manageSpreadInfo={manageSpreadInfo}
            submissionUuid={submissionUuid || ""}
            globalBgColor={globalBgColor}
            globalTextStyle={globalTextStyle}
          />
        </div>

        {/* Slide Navigation */}
        <div className="flex justify-between border-box px-2 py-2 items-center h-[6vh] ">
          <IconButton
            icon="arrow_back"
            className={`rounded-full text-base w-9 h-9 ${
              (manageSpreadInfo().find((info) => info.spreadIndex === index)
                ?.slideIndex === undefined &&
                index === 0) ||
              (manageSpreadInfo().find((info) => info.spreadIndex === index)
                ?.slideIndex === 0 &&
                index === 0)
                ? "hidden"
                : ""
            }`}
            onClick={() => {
              const spreadInfoObject = manageSpreadInfo().find(
                (info) => info.spreadIndex === index
              );

              handleBackArrowClick();
              if (spreadInfoObject) {
                if (spreadInfoObject.slideIndex === 0) {
                  navigateSlide("previous");
                }

                if (spreadInfoObject.slideIndex > 0) {
                  manageSpreadInfo({
                    slideIndex: (spreadInfoObject.slideIndex || 1) - 1,
                    slideLength: spreadInfoObject.slideLength,
                    spreadIndex: index,
                    isBranchingSlide: spreadInfoObject.isBranchingSlide,
                    showResults: spreadInfoObject.showResults,
                  });
                }
              } else {
                navigateSlide("previous");
              }
            }}
            disabled={
              ((isNavDisabled || false) && item?.spreadType !== "carousel") ||
              isBackArrowDisabled
              // item?.spreadType !== "activity-group"
              //   ? isNavDisabled || false
              //   : false
            }
          />

          <ButtonStateSwitch
            spreadType={item?.spreadType || ""}
            spreadIndex={index}
            isRequired={item?.examItem?.required || false}
            spreadLength={spreadLength || 1}
            canSubmit={() => {
              if (
                (item?.spreadType === "activity" &&
                  item?.examItem?.subtype !== "SU") ||
                (item?.spreadType === "activity-group" &&
                  currentActivityGroupSlide.subtype !== "SU")
              )
                return (
                  (response?.choices?.length || 0) > 0 ||
                  !!response?.long_input ||
                  !!response?.short_input
                );

              return (
                (response?.choices?.length || 0) > 0 && !!response?.long_input
              );
            }}
            navigateSlide={navigateSlide}
            alreadyAnswered={alreadyAnswered || false}
            isNavDisabled={isNavDisabled || false}
            currentActivityGroupSlide={currentActivityGroupSlide}
            id={index}
            manageSpreadInfo={manageSpreadInfo}
            // assessmentResponseData={assessmentResponseData?.assessmentSubmitUpdateV2.}
          />
        </div>
      </div>
    );
  }) || [<div key={1}></div>];

  // Submission Uuid SideEffect
  // useEffect(() => {
  //   if (referenceId) {
  //     setExamTimeslotUuid(referenceId);
  //   } else if (
  //     data?.resourceViewScreenV2?.resource?.content?.__typename ===
  //       "ResourceContentBuribookWithActivity" ||
  //     data?.resourceViewScreenV2?.resource?.content?.__typename ===
  //       "ResourceContentBurislide"
  //   )
  //     setExamTimeslotUuid(
  //       data?.resourceViewScreenV2?.resource?.content?.examDetails
  //         ?.examTimeslotUuid || ""
  //     );
  // }, [data, referenceId]);

  useEffect(() => {
    if (referenceId) {
      setExamTimeslotUuid(referenceId);
    } else if (
      data?.resourceViewScreenV2?.resource?.content?.__typename ===
        "ResourceContentBuribookWithActivity" ||
      data?.resourceViewScreenV2?.resource?.content?.__typename ===
        "ResourceContentBurislide"
    )
      setExamTimeslotUuid(
        data?.resourceViewScreenV2?.resource?.content?.examDetails
          ?.examTimeslotUuid || ""
      );
  }, [data, referenceId]);

  // Submission SideEffect
  useEffect(() => {
    if (examTimeslotUuid) {
      getSubmissionUuid({
        variables: {
          examTimeslotUuid: examTimeslotUuid,
          blockUuid: courseBlockUuid,
          courseScheduleUuid: resourceDetailsInput.scheduleUuid,
          submission: {
            status: "in_progress",
            timeslot: examTimeslotUuid,
          },
        },
      });
    }
  }, [examTimeslotUuid]);

  // Time SideEffect
  useEffect(() => {
    const screenDurationTimer = setTimeout(() => {
      if (!loading) setSpreadScreenDuration((prev) => prev + 1);
    }, 500);

    return () => {
      clearTimeout(screenDurationTimer); // Clear the setTimeout
    };
  }, [spreadScreenDuration, loading]);

  // Flag SideEffect
  // This will add a trigger flag entry if the choice exists on trigger flags
  useEffect(() => {
    const choiceUuidArrayOfLastSavedResponse =
      savedResponses?.[savedResponses.length - 1].choices;

    // const triggerFlag : FlagObject = currentTriggerFlags?.find((triggerFlag) =>
    //   choiceUuidArrayOfLastSavedResponse?.includes(
    //     triggerFlag.examChoiceUuid || ""
    //   )
    // );

    const triggerFlag = currentTriggerFlags?.find((flag) =>
      choiceUuidArrayOfLastSavedResponse?.includes(flag.examChoiceUuid || "")
    );
    const triggerFlagObject: FlagObject = {
      spreadIndex: currentSpreadIndex,
      flagId: triggerFlag?.flags?.[0]?.flagId || "",
    };

    if (triggerFlag) setActiveFlags((prev) => [...prev, triggerFlagObject]);
  }, [savedResponses]);

  const lastPageContext = useContext(LastPageContext);

  useEffect(() => {
    if (data && currentSpreadIndex === spreadLength - 1) {
      lastPageContext.handleIsSlideLastPage(true);

      if (isOnline) {
        // update the submission you created to final
        assessmentSubmitV2({
          variables: {
            courseScheduleUuid: resourceDetailsInput.scheduleUuid,
            submissionUuid: submissionUuid || "",
            updatedSubmission: {
              status: "final",
              timeslot: examTimeslotUuid,
              responses: response || [],
              uuid: submissionUuid || "",
            } as ExamSubmissionInputV2,
            blockUuid: courseBlockUuid || null,
          },
        });
      }
    } else lastPageContext.handleIsSlideLastPage(false);
  }, [currentSpreadIndex]);

  if (loading || getSubmissionUuidResponse.loading) {
    return <LoadingCentered />;
  }

  // Display slide
  return (
    <div className={" flex flex-col h-full my-auto justify-center"}>
      {spreadComponentArray[currentSpreadIndex]}
    </div>
  );
}

function ButtonStateSwitch({
  spreadType,
  spreadIndex,
  spreadLength,
  canSubmit,
  navigateSlide,
  alreadyAnswered,
  isRequired,
  isNavDisabled,
  currentActivityGroupSlide,
  id,
  manageSpreadInfo,
}: {
  spreadType: string;
  spreadIndex: number;
  spreadLength: number;
  canSubmit: () => boolean;
  navigateSlide: (
    action: "previous" | "next" | "submit" | "finish",
    autoGoNextLogic?: () => void
  ) => void;
  isNavDisabled: boolean;
  alreadyAnswered: boolean;
  isRequired: boolean;
  currentActivityGroupSlide: ExamItemV2;
  id: number;
  manageSpreadInfo: (spreadInfo?: SpreadInfo) => SpreadInfo[];
}) {
  function buttonContentHandler(
    isRequired: boolean,
    alreadyAnswered: boolean,
    canSubmit: boolean,
    isResultPage?: boolean
  ) {
    const isLastPage = spreadIndex === spreadLength - 1;
    const spreadInfo = manageSpreadInfo().find(
      (info) => info.spreadIndex === id
    );

    if (isResultPage) {
      if (isLastPage) return "Finish";
      return "Continue";
    }

    if (spreadType !== "activity-group") {
      if (isRequired) {
        if (alreadyAnswered) {
          if (isLastPage) return "Finish";
          return "Continue";
        }

        if (canSubmit) {
          return "Submit";
        }

        return "Answer to Continue";
      }

      if (!isRequired) {
        if (alreadyAnswered) {
          if (isLastPage) return "Finish";
          return "Continue";
        }
        if (canSubmit) return "Submit";

        return "Continue";
      }
    }

    if (spreadType === "activity-group") {
      if (isRequired) {
        if (alreadyAnswered) {
          if (
            isLastPage &&
            spreadInfo?.slideIndex === (spreadInfo?.slideLength || 1) - 1
          )
            return "Finish";
          return "Continue";
        }

        if (canSubmit) {
          return "Submit";
        }

        return "Answer to Continue";
      }

      if (!isRequired) {
        if (alreadyAnswered) {
          if (
            isLastPage &&
            spreadInfo?.slideIndex === (spreadInfo?.slideLength || 1) - 1
          )
            return "Finish";
          return "Continue";
        }
        if (canSubmit) return "Submit";

        return "Continue";
      }
    }

    return "";
  }

  if (spreadType === "activity")
    return (
      <Button
        className={"w-fit whitespace-nowrap ml-auto h-9 rounded-full"}
        disabled={
          buttonContentHandler(isRequired, alreadyAnswered, canSubmit()) ===
            "Answer to Continue" || isNavDisabled
        }
        onClick={() => {
          if (
            buttonContentHandler(isRequired, alreadyAnswered, canSubmit()) ===
            "Submit"
          )
            navigateSlide("submit");
          else if (
            buttonContentHandler(isRequired, alreadyAnswered, canSubmit()) ===
            "Finish"
          ) {
            navigateSlide("finish");
          } else navigateSlide("next");
        }}
      >
        {buttonContentHandler(isRequired, alreadyAnswered, canSubmit())}
      </Button>
    );

  if (spreadType === "carousel")
    return (
      <Button
        className={`w-fit whitespace-nowrap ml-auto h-full rounded-full `}
        disabled={isNavDisabled}
        onClick={() => {
          if (spreadIndex === spreadLength - 1) navigateSlide("finish");
          else navigateSlide("next");
        }}
      >
        {spreadIndex === spreadLength - 1 ? "Finish" : "Continue"}
      </Button>
    );

  if (spreadType === "activity-group") {
    const isSlideRequired = currentActivityGroupSlide.required || false;

    const spreadInfo = manageSpreadInfo().find(
      (info) => info.spreadIndex === id
    );
    const isResultPage =
      spreadInfo?.slideIndex === (spreadInfo?.slideLength || 1) - 1 &&
      spreadInfo?.hasResultsPage;

    return (
      <Button
        className={"w-fit whitespace-nowrap ml-auto h-9 rounded-full"}
        disabled={
          buttonContentHandler(
            isSlideRequired,
            alreadyAnswered,
            canSubmit()
          ) === "Answer to Continue" || isNavDisabled
        }
        onClick={() => {
          if (
            buttonContentHandler(
              isSlideRequired,
              alreadyAnswered,
              canSubmit(),
              isResultPage
            ) === "Submit"
          ) {
            const autoGoNextLogic = () => {
              const isLastPage = spreadIndex === spreadLength - 1;
              if (!currentActivityGroupSlide.showResults) {
                if (
                  spreadInfo?.slideIndex !==
                  (spreadInfo?.slideLength || 1) - 1
                ) {
                  manageSpreadInfo({
                    slideIndex: (spreadInfo?.slideIndex || 0) + 1,
                    slideLength: spreadInfo?.slideLength || 1,
                    spreadIndex: spreadInfo?.spreadIndex || 0,
                    itemUuid: spreadInfo?.itemUuid || "",
                    hasResultsPage: spreadInfo?.hasResultsPage,
                    showResults: spreadInfo?.showResults || false,
                    isBranchingSlide: spreadInfo?.isBranchingSlide || false,
                  });
                } else {
                  if (!isLastPage) {
                    navigateSlide("next");
                  }
                }
              }
            };
            navigateSlide("submit", autoGoNextLogic);
          } else if (
            buttonContentHandler(
              isSlideRequired,
              alreadyAnswered,
              canSubmit(),
              isResultPage
            ) === "Finish"
          ) {
            navigateSlide("finish");
          } else if (
            spreadInfo?.slideIndex ===
            (spreadInfo?.slideLength || 1) - 1
          ) {
            navigateSlide("next");
          }
          // navigates activity group
          else {
            manageSpreadInfo({
              slideIndex: (spreadInfo?.slideIndex || 0) + 1,
              slideLength: spreadInfo?.slideLength || 1,
              spreadIndex: spreadInfo?.spreadIndex || 0,
              itemUuid: spreadInfo?.itemUuid || "",
              hasResultsPage: spreadInfo?.hasResultsPage,
              showResults: spreadInfo?.showResults || false,
              isBranchingSlide: spreadInfo?.isBranchingSlide || false,
            });
          }
        }}
      >
        {buttonContentHandler(
          isSlideRequired,
          alreadyAnswered,
          canSubmit(),
          isResultPage
        )}
      </Button>
    );
  }

  return (
    <Button
      className={`w-fit whitespace-nowrap ml-auto h-full rounded-full `}
      disabled={false || isNavDisabled}
      onClick={() => {
        if (spreadIndex === spreadLength - 1) navigateSlide("finish");
        else navigateSlide("next");
      }}
    >
      {spreadIndex === spreadLength - 1 ? "Finish" : "Continue"}
    </Button>
  );
}

export function SpreadTypeSwitch({
  item,
  savedResponses,
  handleResponse,
  savedFeedbacks,
  manageNavDisabled,
  manageItemFlags,
  activeFlags,
  manageCurrentActivityGroupSlide,
  id,
  manageSpreadInfo,
  submissionUuid,
  globalBgColor,
  globalTextStyle,
}: {
  item: ResourceContentBurislideSpread;
  savedResponses: ExamResponseInput[];
  handleResponse: (response: ExamResponseInput) => void;
  savedFeedbacks: ExamResponse[];
  manageNavDisabled: (isNavDisabled: boolean) => void;
  activeFlags: FlagObject[];
  manageItemFlags?: (
    examChoiceTriggerFlags: ResourceContentBurislideSpreadActivityGroupExamChoiceTriggerFlag[],
    examItemRequiredFlags: ResourceContentBurislideSpreadActivityGroupExamItemRequiredFlag[]
  ) => void;
  manageCurrentActivityGroupSlide?: (examItem: ExamItemV2) => void;
  id: number;
  manageSpreadInfo: (spreadInfo?: SpreadInfo) => SpreadInfo[];
  submissionUuid?: string;
  globalBgColor: ColorObj;
  globalTextStyle: ColorObj;
}) {
  const spreadType = item.spreadType || "";
  const [isImageLoading, setisImageLoading] = useState<boolean>(true);
  const [isLeftImageLoading, setIsLeftImageLoading] = useState<boolean>(true);
  const [isRightImageLoading, setIsRightImageLoading] = useState<boolean>(true);
  const transformWrapperRef = useRef<any>(null);
  const [wrapperDimensions, setWrapperDimensions] = useState({
    width: 1,
    height: 1,
  });

  const leftBlobUrl = useCreateUrlFromBlob(item.leftUri);
  const rightBlobUrl = useCreateUrlFromBlob(item.rightUri);
  const slideBlobUrl = useCreateUrlFromBlob(item.uri);

  const textStyle = parseColorToStyle({
    foregroundColor: item.appearance?.base?.foregroundColor,
    fontFamily: item.appearance?.base?.fontFamily,
  });

  const bgColor = parseColorToStyle({
    backgroundColor: item.appearance?.base?.backgroundColor,
  });

  const finalBgColor = item.appearance ? bgColor : globalBgColor;
  const finalTextStyle = item.appearance ? textStyle : globalTextStyle;

  const finalAppearance: { finalBgColor: ColorObj; finalTextStyle: ColorObj } =
    {
      finalBgColor: finalBgColor,
      finalTextStyle: finalTextStyle,
    };

  useLayoutEffect(() => {
    const updateParentDimensions = () => {
      if (transformWrapperRef.current) {
        const { width, height } =
          transformWrapperRef.current.getBoundingClientRect();
        setWrapperDimensions({ width, height });
      }
    };

    updateParentDimensions();
    window.addEventListener("resize", updateParentDimensions);

    return () => {
      window.removeEventListener("resize", updateParentDimensions);
    };
  }, []);

  function leftPage(
    handleRightPageFeedback: (feedback: ExamResponse) => void,
    feedbackForThisActivity: ExamResponse | false,
    examItemType?: string,
    activityGroupExamItem?: ExamItemV2
  ) {
    const hasCorrectAnswer = !(
      item.examItem?.type === ExamItemType.LONG_ANSWER ||
      item.examItem?.type === ExamItemType.TALLY ||
      item.examItem?.type === ExamItemType.POLL ||
      item.examItem?.type === ExamItemType.FREEDOM_WALL ||
      examItemType === ExamItemType.LONG_ANSWER ||
      examItemType === ExamItemType.TALLY ||
      examItemType === ExamItemType.POLL ||
      examItemType === ExamItemType.FREEDOM_WALL
    );

    if (feedbackForThisActivity && item.spreadType === "activity-group") {
      const isResponseCorrect = feedbackForThisActivity.isResponseCorrect;
      handleRightPageFeedback(feedbackForThisActivity);
      return (
        <div
          className={twMerge(
            `w-full ${
              activityGroupExamItem?.subtype !== "SU"
                ? "md:w-1/2 md:rounded-r-none max-h-60 "
                : "rounded-r-lg h-full"
            } md:max-h-full rounded-t-lg  md:rounded-l-lg animate-fadeIn relative overflow-y-auto custom-scrollbar scrollbar-color-gray p-10 ${
              activityGroupExamItem?.showResults && hasCorrectAnswer
                ? isResponseCorrect
                  ? "bg-success-300 scrollbar-color-success"
                  : "bg-error-300 scrollbar-color-error "
                : "bg-gray-200"
            }`
          )}
        >
          <Markdown
            className="mb-8 text-gray-700 "
            text={
              feedbackForThisActivity.feedbackMessage ||
              (activityGroupExamItem?.showResults && hasCorrectAnswer
                ? isResponseCorrect
                  ? "Great Job, You got the right answer!"
                  : "Too bad, You got the wrong answer"
                : "")
            }
          />

          {feedbackForThisActivity.feedbackImageUrl ? (
            <Image
              src={feedbackForThisActivity.feedbackImageUrl || ""}
              className="w-full md:h-fit max-h-[80%]"
            ></Image>
          ) : null}
        </div>
      );
    }

    if (feedbackForThisActivity && item.spreadType !== "activity-group") {
      const isResponseCorrect = feedbackForThisActivity.isResponseCorrect;
      handleRightPageFeedback(feedbackForThisActivity);
      return (
        <div
          className={`w-full ${
            item.examItem?.subtype !== "SU"
              ? "md:w-1/2 md:rounded-r-none max-h-60 "
              : "rounded-r-lg h-full"
          } md:max-h-full rounded-t-lg  md:rounded-l-lg animate-fadeIn relative overflow-y-auto custom-scrollbar scrollbar-color-gray p-10 ${
            item.examItem?.showResults && hasCorrectAnswer
              ? isResponseCorrect
                ? "bg-success-300 scrollbar-color-success"
                : "bg-error-300 scrollbar-color-error "
              : "bg-gray-200"
          }`}
        >
          {/* If there is a background image this should appear */}
          {/* <h1 className="mb-8 text-5xl font-bold text-gray-700">
            {item.examItem?.showResults && hasCorrectAnswer
              ? isResponseCorrect
                ? "Correct!"
                : "Incorrect!"
              : "Your Answer Has Been Submitted!"}
          </h1> */}

          <Markdown
            className="mb-8 text-gray-700"
            text={
              feedbackForThisActivity.feedbackMessage ||
              (item.examItem?.showResults && hasCorrectAnswer
                ? isResponseCorrect
                  ? "Great Job, You got the right answer!"
                  : "Too bad, You got the wrong answer"
                : "")
            }
          />

          {feedbackForThisActivity.feedbackImageUrl ? (
            <Image
              src={feedbackForThisActivity.feedbackImageUrl || ""}
              className="w-full md:h-fit max-h-[80%]"
            ></Image>
          ) : null}
        </div>
      );
    }

    if (
      (item.examItem?.mediaUrl && item.examItem?.subtype !== "SU") ||
      (activityGroupExamItem?.mediaUrl &&
        activityGroupExamItem.subtype !== "SU")
    ) {
      return (
        <Image
          src={
            activityGroupExamItem?.mediaUrl ||
            item.examItem?.mediaUrl ||
            defaultPhoto
          }
          className="object-cover w-full rounded-t-lg md:w-1/2 md:rounded-r-none md:rounded-l-lg max-h-60 md:max-h-full"
        ></Image>
      );
    }

    return "";
  }

  useEffect(() => {
    if (item.spreadType === "slide") {
      setisImageLoading(true);
    }

    if (item.spreadType === "image-spread") {
      if (item.leftUri) setIsLeftImageLoading(true);
      else setIsLeftImageLoading(false);

      if (item.rightUri) setIsRightImageLoading(true);
      else setIsRightImageLoading(false);
    }
  }, [item]);

  if (spreadType === "slide") {
    return (
      <div className="relative flex items-center h-full " style={finalBgColor}>
        {isImageLoading ? (
          <div className="absolute top-0 bottom-0 left-0 right-0 mx-auto my-auto text-center w-36 h-36">
            <Loading />
            Loading Image
          </div>
        ) : (
          " "
        )}
        <Image
          // src={item.uri || ""}
          src={slideBlobUrl ? slideBlobUrl : item.uri || ""}
          alt={item.uri || ""}
          className={`w-full max-h-full ${
            isImageLoading ? "opacity-0" : "opacity-100"
          }`}
          onLoad={() => {
            setisImageLoading(false);
          }}
        ></Image>
      </div>
    );
  }

  if (spreadType === "activity") {
    let rightPageFeedback: ExamResponse = {};

    const feedbackForThisActivity =
      savedFeedbacks.find((feedback) => feedback.item === item.examItemUuid) ||
      false;

    function handleRightPageFeedback(rightPageFeedbackObject: ExamResponse) {
      rightPageFeedback = rightPageFeedbackObject;
    }

    return (
      <div className="flex flex-col h-full border-2 border-black rounded-lg md:flex-row">
        {/* Left page */}
        {leftPage(handleRightPageFeedback, feedbackForThisActivity)}

        {/* Right page */}
        {(!feedbackForThisActivity || item.examItem?.subtype !== "SU") && (
          <div
            className={`w-full md:w-1/2 flex-1 bg-gray-100 rounded-b-lg relative ${
              item.examItem?.mediaUrl || rightPageFeedback.uuid
                ? "md:rounded-r-lg  md:rounded-l-none"
                : "rounded-lg"
            } md:rounded-r-lg px-4 p-10 overflow-y-auto custom-scrollbar scrollbar-color-gray bg-center bg-cover `}
            style={
              item.examItem?.subtype === "SU" && item.examItem?.mediaUrl
                ? {
                    backgroundImage: `url(${item.examItem?.mediaUrl})`,
                  }
                : finalBgColor
            }
          >
            <ActivityTypeSwitch
              examItem={item.examItem as ExamItemV2}
              handleResponse={handleResponse}
              savedResponses={savedResponses}
              rightPageFeedback={rightPageFeedback}
              finalAppearance={finalAppearance}
            />
          </div>
        )}
      </div>
    );
  }

  if (spreadType === "activity-group") {
    if (manageItemFlags)
      manageItemFlags(
        item.examChoiceTriggerFlags as ResourceContentBurislideSpreadActivityGroupExamChoiceTriggerFlag[],
        item.examItemRequiredFlags as ResourceContentBurislideSpreadActivityGroupExamItemRequiredFlag[]
      );

    return (
      <ActivityGroupSlide
        key={item.examItemUuid}
        item={item}
        handleResponse={handleResponse}
        savedResponses={savedResponses}
        savedFeedbacks={savedFeedbacks}
        leftPage={leftPage}
        activeFlags={activeFlags}
        manageCurrentActivityGroupSlide={manageCurrentActivityGroupSlide}
        id={id}
        manageNavDisabled={manageNavDisabled}
        manageSpreadInfo={manageSpreadInfo}
        submissionUuid={submissionUuid || ""}
        finalAppearance={finalAppearance}
      />
    );
  }

  if (spreadType === "link")
    return (
      <div
        className="flex items-center justify-center h-full bg-gray-100 border border-gray-700 rounded-lg"
        style={finalAppearance.finalBgColor}
      >
        <ViewResourceLink
          link={item.uri || ""}
          textColor={finalAppearance.finalBgColor}
        />
      </div>
    );

  if (spreadType === "download-link")
    return (
      <div
        className="flex flex-col items-center justify-center w-full h-full space-y-10 bg-gray-200 bg-center bg-cover border border-gray-700 rounded-lg"
        style={
          item.imageUri
            ? {
                backgroundImage: `url(${item.imageUri})`,
              }
            : finalAppearance.finalBgColor
        }
      >
        {/* {item.imageUri && (
          <Image className="h-fit min-h-72 max-h-80" src={item.imageUri} />
        )} */}

        <div className="p-4 text-center text-gray-700 rounded-lg w-fit ">
          <h1
            className="text-2xl font-bold"
            style={finalAppearance.finalTextStyle}
          >
            {item.title || ""}
          </h1>
          <h2 className="text-lg" style={finalAppearance.finalTextStyle}>
            {item.text || ""}
          </h2>
        </div>

        <ExternalLink uri={item.uri || ""}>
          <Button className="rounded-full">Download</Button>
        </ExternalLink>
      </div>
    );

  if (spreadType === "video")
    return (
      <div className="flex h-full" style={finalAppearance.finalBgColor}>
        {/* <ViewResourceVideo
          file={{ uri: item.uri } as VideoObject}
          className="w-full h-full max-h-full p-1 m-auto overflow-y-auto"
          style={textStyle}
          includeTranscriptButton={false}
        /> */}
        <Video
          file={{ uri: item.uri } as VideoObject}
          includeTranscriptButton={false}
          className="flex items-center w-full h-full "
        />
      </div>
    );
  if (spreadType === "pdf")
    return (
      <ViewResourcePdf
        uri={item.uri || ""}
        style={finalAppearance.finalBgColor}
      />
    );
  if (spreadType === "html" || spreadType === "markdown")
    return (
      <div
        className="h-full overflow-y-auto "
        style={finalAppearance.finalBgColor}
      >
        <ViewResourceMarkdown
          text={item.text || ""}
          style={finalAppearance.finalTextStyle}
        />
      </div>
    );

  if (spreadType === "carousel" && item.viewMode !== "scroll") {
    return (
      <CarouselSlide
        item={item}
        manageNavDisabled={manageNavDisabled}
        finalAppearance={finalAppearance}
      />
    );
  }

  if (spreadType === "carousel" && item.viewMode === "scroll") {
    return (
      <ScrollCarouselSlide
        item={item}
        manageNavDisabled={manageNavDisabled}
        finalAppearance={finalAppearance}
      />
    );
  }

  if (spreadType === "image-spread") {
    return (
      <>
        <TransformWrapper
          initialScale={1}
          centerZoomedOut={true}
          disablePadding={true}
          minScale={0.5}
          maxScale={7}
        >
          {({ centerView }) => (
            <div
              ref={transformWrapperRef}
              className="relative flex items-center justify-center w-full h-full bg-gray-200 rounded-lg "
              style={finalAppearance.finalBgColor}
            >
              {isLeftImageLoading || isRightImageLoading ? (
                <div className="absolute top-0 bottom-0 left-0 right-0 mx-auto my-auto text-center w-36 h-36">
                  <Loading />
                  Loading Image
                </div>
              ) : null}

              <div
                style={{
                  position: "relative",
                  width: wrapperDimensions.width,
                  height: wrapperDimensions.height,
                }}
              >
                <TransformComponent
                  wrapperStyle={{
                    width: `${wrapperDimensions.width}px`,
                    height: `${wrapperDimensions.height}px`,
                  }}
                >
                  <>
                    {item.leftUri && (
                      <div id="leftImage" className="flex justify-end w-1/2">
                        <Image
                          // src={item.leftUri}
                          src={leftBlobUrl ? leftBlobUrl : item.leftUri}
                          className={`h-full w-auto ${
                            isLeftImageLoading || isRightImageLoading
                              ? "opacity-0"
                              : "opacity-100"
                          }`}
                          style={{
                            maxHeight: `${wrapperDimensions.height}px`,
                            maxWidth: `${wrapperDimensions.width / 2}px`,
                          }}
                          onLoad={() => {
                            setIsLeftImageLoading(false);
                            centerView(undefined, 0);
                          }}
                        />
                      </div>
                    )}
                    {item.rightUri && (
                      <div id="rightImage" className="w-1/2">
                        <Image
                          // src={item.rightUri}
                          src={rightBlobUrl ? rightBlobUrl : item.rightUri}
                          className={`h-full w-auto ${
                            isLeftImageLoading || isRightImageLoading
                              ? "opacity-0"
                              : "opacity-100"
                          }`}
                          style={{
                            maxHeight: `${wrapperDimensions.height}px`,
                            maxWidth: `${wrapperDimensions.width / 2}px`,
                          }}
                          onLoad={() => {
                            setIsRightImageLoading(false);
                            centerView(undefined, 0);
                          }}
                        />
                      </div>
                    )}
                  </>
                </TransformComponent>
              </div>
            </div>
          )}
        </TransformWrapper>
      </>
    );
  }

  return <div>{item.spreadType}</div>;
}

export type ActivityProps = {
  examItem: ExamItemV2;
  savedResponses: ExamResponseInput[];
  handleResponse: (response: ExamResponseInput) => void;
  rightPageFeedback?: ExamResponse;
  finalAppearance?: {
    finalTextStyle: ColorObj;
    finalBgColor: ColorObj;
  };
};

export function ActivityTypeSwitch({
  examItem,
  savedResponses,
  handleResponse,
  rightPageFeedback,
  finalAppearance,
}: ActivityProps) {
  if (
    examItem.type === ExamItemType.MULTIPLE_CHOICE ||
    examItem.type === ExamItemType.I_CHOICE
  ) {
    return (
      <MultipleChoiceSlideActivity
        examItem={examItem}
        savedResponses={savedResponses}
        handleResponse={handleResponse}
        rightPageFeedback={rightPageFeedback}
        finalAppearance={finalAppearance}
      />
    );
  }

  if (examItem.type === ExamItemType.POLL)
    return (
      <PollSlideActivity
        examItem={examItem}
        savedResponses={savedResponses}
        handleResponse={handleResponse}
        rightPageFeedback={rightPageFeedback}
        finalAppearance={finalAppearance}
      />
    );

  if (examItem.type === ExamItemType.LONG_ANSWER) {
    return (
      <LongAnswerSlideActivity
        examItem={examItem}
        savedResponses={savedResponses}
        handleResponse={handleResponse}
        finalAppearance={finalAppearance}
      />
    );
  }

  if (examItem.type === ExamItemType.SHORT_ANSWER) {
    return (
      <ShortAnswerSlideActivity
        examItem={examItem}
        savedResponses={savedResponses}
        handleResponse={handleResponse}
        rightPageFeedback={rightPageFeedback}
        finalAppearance={finalAppearance}
      />
    );
  }

  if (examItem.type === ExamItemType.CHECK_BOX) {
    return (
      <CheckBoxSlideActivity
        examItem={examItem}
        savedResponses={savedResponses}
        handleResponse={handleResponse}
        finalAppearance={finalAppearance}
      />
    );
  }

  if (examItem.type === ExamItemType.FREEDOM_WALL) {
    return (
      <FreedomWallSlideActivity
        examItem={examItem}
        savedResponses={savedResponses}
        handleResponse={handleResponse}
        rightPageFeedback={rightPageFeedback}
        finalAppearance={finalAppearance}
      />
    );
  }

  if (examItem.type === ExamItemType.TALLY) {
    return (
      <TallySlideActivity
        examItem={examItem}
        savedResponses={savedResponses}
        handleResponse={handleResponse}
        rightPageFeedback={rightPageFeedback}
        finalAppearance={finalAppearance}
      />
    );
  }

  return <div>Type {examItem.type} is not available</div>;
}

export function QuestionHeader({
  text,
  style,
}: {
  text: string;
  style?: React.CSSProperties;
}) {
  return (
    <h1
      className="flex items-center mb-4 text-2xl font-bold text-gray-700"
      style={style}
    >
      <Markdown text={text} />
    </h1>
  );
}

export const getDefaultValue = ({
  examItem,
  savedResponses,
}: {
  examItem: ExamItemV2;
  savedResponses: ExamResponseInput[];
}) => {
  if (savedResponses.some((response) => response.item === examItem.uuid)) {
    if (
      examItem.type === ExamItemType.MULTIPLE_CHOICE ||
      examItem.type === ExamItemType.POLL ||
      examItem.type === ExamItemType.TALLY
    ) {
      const matchingResponse = savedResponses.find(
        (response) => response.item === examItem.uuid
      );
      return matchingResponse?.choices?.[0] || "";
    }

    if (
      examItem.type === ExamItemType.SHORT_ANSWER ||
      examItem.type === ExamItemType.LONG_ANSWER ||
      examItem.type === ExamItemType.FREEDOM_WALL
    ) {
      const matchingResponse = savedResponses.find(
        (response) => response.item === examItem.uuid
      );
      return (
        matchingResponse?.long_input || matchingResponse?.short_input || ""
      );
    }

    if (examItem.type === ExamItemType.CHECK_BOX) {
      const matchingResponse = savedResponses.find(
        (response) => response.item === examItem.uuid
      );
      return matchingResponse?.choices || [];
    }
  }

  if (examItem.subtype === "DD" && examItem.choices?.[0]?.uuid) {
    return examItem.choices[0].uuid;
  }

  return "";
};

export const getFeedbackColor = ({
  selected,
  choiceUuid,
  hasAnswered,
  isCorrect,
  showResults,
}: {
  selected: string | string[];
  choiceUuid: string;
  hasAnswered: boolean;
  isCorrect: boolean;
  showResults: boolean;
}) => {
  if (typeof selected === "string")
    return selected === choiceUuid
      ? !hasAnswered || !showResults
        ? " bg-gray-300 active:bg-gray-400 text-gray-700 "
        : isCorrect
        ? "bg-success-200"
        : "bg-error-200"
      : "bg-white";

  return selected.includes(choiceUuid)
    ? !hasAnswered || !showResults
      ? " bg-gray-300 active:bg-gray-400 text-gray-700 "
      : isCorrect
      ? "bg-success-200"
      : "bg-error-200"
    : "bg-white";
};

export const getIconColor = ({
  selected,
  choiceUuid,
  hasAnswered,
  isCorrect,
  showResults,
}: {
  selected: string[];
  choiceUuid: string;
  hasAnswered: boolean;
  isCorrect: boolean;
  showResults: boolean;
}) => {
  return selected.includes(choiceUuid)
    ? !hasAnswered || !showResults
      ? "bg-gray-200 active:bg-gray-300 text-purple-700"
      : isCorrect
      ? "text-success-700"
      : "text-error-700"
    : "text-primary-700";
};
